<template>
    <div class="h-full w-full flex justify-center items-center">
      <div class="flex flex-col w-4/5 text-white bg-black bg-opacity-30 rounded p-5 space-y-5">
        <div class="flex">
            <p class="text-gray-300 font-bold">Einen neuen Account erstellen</p>
        </div>
        <div class="flex justify-between items-center space-x-5">
          <p class="text-gray-400 w-1/12">Email</p>
          <div class="w-full h-full flex space-x-2">
            <input v-model="emailInput" class="w-full bg-black bg-opacity-50 h-10 p-3 border-hidden rounded">
            <div v-if="emailInput" class="flex justify-center items-center">
              <b-icon-check-circle v-if="isValidEmail" class="w-6 h-6 text-green-500"></b-icon-check-circle>
              <b-icon-x-circle v-if="!isValidEmail && emailInput" class="w-6 h-6 text-red-500"></b-icon-x-circle>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center space-x-5">
          <p class="text-gray-400 w-1/12">Username</p>
          <input v-model="usernameInput" class="w-full bg-black bg-opacity-50 h-10 p-3 border-hidden rounded">
        </div>
        <div class="flex justify-between items-center space-x-5">
          <p class="text-gray-400 w-1/12">Password</p>  
          <input type="password" v-model="passwordInput" class="w-full bg-black bg-opacity-50 h-10 p-3 border-hidden rounded">
        </div>
        <div class="flex justify-around items-center space-x-5 w-full">
          <div @click="signUp" class="flex justify-center items-center text-center rounded p-5 h-8 bg-green-500 hover:bg-green-700 cursor-pointer">
            <p class="text-gray-100">Sign Up</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: 'RegisterView',
    data() {
      return {
        infoMessages: {},
        usernameInput: "",
        passwordInput: "",
        emailInput: "",
      }
    },
    computed: {
      isValidEmail() {
        return /^[^@]+@\w+(\.\w+)+\w$/.test(this.emailInput);
      }
    },
    methods: {
      signUp(){
          // Check the inputs
          // hash password with salt?
          //todo
          this.postSignUp(this.usernameInput, this.emailInput, this.passwordInput)
      },
      async postSignUp(username, email, password){
        const body = {
          'username': username,
          'email': email,
          'password': password
        }
        await axios.post('/auth/register', body).then((res) => {
          console.log(res)
        })
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  </style>
  