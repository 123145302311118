<template>
    <div class="h-full w-full flex justify-center items-center">
      <div class="flex flex-col w-4/5 text-white bg-black bg-opacity-30 rounded p-5 space-y-5">
        <div class="space-y-2">
          <p class="text-gray-300">Username</p>
          <input v-model="usernameInput" class="w-full bg-black bg-opacity-50 h-10 p-3 border-hidden rounded">
        </div>
        <div class="space-y-2">
          <p class="text-gray-300">Password</p>  
          <input v-model="passwordInput" class="w-full bg-black bg-opacity-50 h-10 p-3 border-hidden rounded">
        </div>
        <div class="flex justify-around items-center space-x-5 w-full">
          <div class="flex justify-center items-center text-center rounded p-5 h-8 bg-green-500 cursor-pointer">
            <p class="text-gray-100">Login</p>
          </div>
        </div>
        <div class="flex justify-center space-x-1 items-center text-center rounded p-5 h-8 cursor-pointer">
          <p class="text-gray-100">Don't have an account? Register</p>
          <router-link to="/register">
            <p class="text-blue-500">here</p>
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: 'LoginView',
    data() {
      return {
        usernameInput: "",
        passwordInput: "",
      }
    },
    methods: {
      showAllUsers(){
        axios.get('/user/allUsers').then((res) => {
          console.log(res)
        })
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  </style>
  