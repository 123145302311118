import { createApp } from 'vue'
import App from './App.vue'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import axios from 'axios'
import router from './router'
import './index.css'

const app = createApp(App)
axios.defaults.baseURL = "http://localhost:5000/api/"
app.use(BootstrapIconsPlugin);
app.use(router).mount('#app')
